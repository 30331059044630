import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">社宅公共藝術咖啡桌 / 第二輪</h2>
          <h1 className="main_title">#城市策略</h1>
        </div>

        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_7">
              <figure className="figure">
                <img src={require("../../images/blog/post_2_9_2.jpg")} alt=""/>
                <figcaption className="figcaption left">組長：孫啟榕<br/>組員：沈伯丞、張柏韋、陳青琳</figcaption>
              </figure>
            </div>
          </section>
          <section className="row_area_s">
            <div className="column_5">
              <div className="context">

                <p>這輪討論了城市策略的都市設計導向、社區公共空間及城市生態保育。原有議題講的是實體空間操作，這組做了更整體的討論。</p>

                <p>台北盆地的環境，四周山系圍繞，淡水河、基隆河、景美溪流經，交織出新/舊市區、城市/自然、河/山等不同交界。社宅公共藝術在這個環境關係中，如何處理、產生新的創作？他們提出幾個不同的主張。</p>

                <h4>1. 「社區就是一個生態系」</h4>
                <p>組員提出「社區就是一個生態系」觀點，重新看待都市策略的架構，城市策略是為了未來的機制，「面對未來的可能」是城市策略的重要課題。現階段的自然資源幾乎用盡，社會已經沒有任何「Nature」，不應以高齡少子化來劃分未來城市的趨勢，而是思考要教育什麼、如何形塑下一代。如果提不出讓地方變得最好的提案，也許應適度的「留白」，保留一個課題給下一代，我們無法解決每個課題，相信年輕人並期待他們做出最理智、負責的決定。</p>

                <h4>2. 運用傳統「廟埕」的概念，看待都市設計、社區公共空間以及城市生態保育的子議題。</h4>
                <ul className="context_list order_list">
                  <li>廟埕是臺灣獨特的信仰空間，也是凝聚地方向心力、公共認同的空間。</li>
                  <li>將社區看作一個生態系，針對不同社宅基地的自然生態、地理、水文，進行歷史性與記憶性連結，透過人為規劃與社區參與，在社宅空間重新創造出一個人為生態系，成為社區的「埕」，是在地的精神據點、生活交流空間。<br/>如此，社區創造公共藝術的同時也在創造公共空間，來凝聚地方意識、公共認同。</li>
                  <li>反思現代臺灣都市設計充滿西方性規劃，能否以「埕」的概念，重塑建築空間，進一步創造獨特的城市意象。</li>
                </ul>

                <h4>3. 榮譽與尊嚴的建立</h4>
                <p>面對社宅住民六年循環的狀態，社區榮譽感與尊嚴要建立在什麼地方？以下分享兩個案例：</p>

                <ul className="context_list order_list">
                  <li>民生社區有一個內部網絡，是在地養狗人為寵物設立LINE群組，他們互相關心，建立良好溝通機制，感覺彼此被連結，有共同的信仰。我們期待公共藝術可以是建立整個城市榮譽感、尊嚴和認同的開端。</li>
                  <li>西元2000年左右，北投優雅路邊的樟樹群，因為馬路建設影響生存，當時地方為了樹的存活做了很多照顧。一位日本的社造老師來到這裡，認為如果對待一棵樹可以如此，更何況人。社區應該為這件事感到驕傲。</li>
                </ul>
                <p>總合上述主張與案例，孫啟榕認為城市策略應該往人的方向前進，城市策略不是見樹不見林，是看到在課題背後的人，並相信他們。</p>

                <h5>補充成員討論內容</h5>
                <p>公共藝術能否回應臺北盆地的環境生態？舉外雙溪的「少少感覺實驗室」為例，以建構在山邊的藝術空間，舉辦藝術活動，吸引市民走進郊山，帶領人們認識環境。</p>
                <p>如畫一張臺北盆地的剖面圖，可見河流─大同萬華舊市區─中正大安高樓─信義101─南港山系(詳手繪剖面)的都市結構，臺北市社宅位在新/舊城區、河/山等不同交界，公共藝術如何回應這樣的環境關係？</p>
                <p>都市熱島效應，市區增溫快，臺北的風沿基隆河由東北吹向西南，上升的熱空氣南飄被二格山擋住，暴雨下在城南、中永和及新店一帶，是往後要面對的極端氣候狀態。</p>

              </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_2_10" className="next_page">
            <div className="next_title">#回饋與討論</div>
            <div className="next_owner">社宅公共藝術咖啡桌 / 第二輪</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
